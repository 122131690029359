.Addproduct{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
.Addproduct1{
    height: 100%;
    width: 99%;
    background-color: #f5f5f5;
    padding: 2%;
}