.login{
    width: 100vw;
    height: 100vh;
    background-color: #0096FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}
.login-content{
    width: 25vw;
    height: 40vh;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.login-content input[type=button]{
    background-color: #0096FF;
    border: none;
    outline: none;
    width: 250px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}

.login-textbox input{
    outline: none;
    width: 250px;
    height: 30px;
    border: none;
    border-bottom: 1px solid black;
}

.login-textbox{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
