.billTab{
    width: 100%;
    height: 95vh;
}
.billTab1{
    width: 100%;
    display: flex;
    background-color: #c0c0c0;
    /* border-bottom: 1px solid black; */
}
.billTab2{
    margin: 0px 2px;
    padding: 2px 5px;
    background-color: #fff;
    /* border: 1px solid black; */
    border-radius: 5px 5px 0px 0px;
    transform: skew(-10deg);
}
button{
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 18px;
}